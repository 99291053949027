// @import url('https://fonts.cdnfonts.com/css/caslon-antique');
@font-face {
  font-family: 'Caslon Antique';
  font-weight: normal;
  src: url(https://fonts.cdnfonts.com/s/25309/Caslo-an.woff);
}

$headerFont: 'Caslon Antique', 'Times New Roman', Times, serif;
$printFont: 'Times New Roman', Times, serif;
$breakpointSmall: 699px;
$breakpointLarge: 899px;

@mixin expandedList {
  display: list-item;
  margin-left: 1.5rem;
  padding-left: 0;

  @media print {
    margin-left: .1875in;
  }

  ul,
  ol {
    li {
      p {
        margin: 1em 0;
      }
    }
  }

  ul {
    list-style-type: "\2013\00A0\00A0";
  }
}

.armyList {
  margin: 0 auto;
  padding: 0;
  max-width: 960px;
  padding-top: 3rem;
  border-top: 1px solid #ccc;

  @media screen and (min-width: $breakpointSmall) {
    padding: 3rem 4rem;
    border: 1px solid #ccc;
  }

  @media print {
    margin: 0;
    padding: 0;
    max-width: none;
    border: none;
  }

  * {
    font-size: 1rem;

    @media print {
      font-family: $printFont;
      margin-top: 0;
      margin-bottom: .125in;
      font-size: 9pt;
      line-height: 1.2;
    }
  }

  b,
  strong {
    font-weight: bold;
  }

  .embeddedAsset {
    font-style: italic;
  }

  .image {
    margin-left: auto;
    margin-right: auto;
    max-width: 90%;
    height: auto;
    display: block;
  }

  h1, h2 {
    @media print {
      font-family: $headerFont;
      line-height: 1.2;
      font-weight: normal;
      text-transform: uppercase;
    }
  }

  h1 {
    margin-top: 0;
    font-size: 2.25rem;
    text-align: center;

    @media screen and (min-width: $breakpointSmall) {
      font-size: 3.5rem;
    }

    @media print {
      font-size: 24pt;
    }
  }

  h2 {
    margin-top: 3rem;
    font-size: 1.625rem;

    @media screen and (min-width: $breakpointSmall) {
      font-size: 2.25rem;
    }

    @media screen and (min-width: $breakpointSmall) {
      margin-top: 4rem;
    }

    @media print {
      margin-top: 0;
      font-size: 16pt;
    }
  }

  h3 {
    font-size: 1.25rem;
    text-align: center;
    text-transform: uppercase;

    @media screen and (min-width: $breakpointSmall) {
      font-size: 1.5rem;
    }

    @media print {
      font-size: 10pt;
    }
  }

  ul,
  ol {
    margin-top: 0;
    padding-left: 1.5rem;

    @media print {
      padding-left: .1875in;
    }

    li {
      @media screen {
        margin-bottom: 1rem;
      }

      p {
        @media screen {
          margin-bottom: 1rem;
        }
      }
    }
  }

  hr {
    margin: 2rem 0;
    border: 0;
    border-top: 1px solid #000;

    @media print {
      margin-top: .125in;
      margin-bottom: .125in;
    }
  }

  blockquote {
    margin: 0;
    padding: 0;
    background: none;
    border: 0;

    p {
      font-weight: bold;
    }
  }

  .richText {
    h2,
    :not(.magicItem) h3 {
      margin: 1em 0;
      text-transform: none;
      font-weight: 700;
      font-size: 1em;

      @media print {
        font-size: 9pt;
        font-family: $printFont;
      }
    }
  }

  &:not(.unbroken) {
    .armyList__section {
      break-inside: avoid;
      break-before: column;
      break-after: column;

      &:first-of-type {
        break-inside: auto !important;
      }
    }

    .armyList__unit {
      break-inside: avoid;
    }

    .avoidBreak {
      .armyList__rule {
        @media print {
          break-inside: avoid;
        }
      }
    }
  }
}

.armyList__print {  
  margin: 0 auto 2rem;
  text-align: center;

  @media print {
    display: none !important;
  }

  .instructions {
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }

  .printOptions {
    margin-bottom: 2rem;
    text-align: left;

    .checkboxes, {
      column-count: none;

      @media screen and (min-width: $breakpointSmall) {
        column-count: 2;
        column-gap: 1em;
      }

      @media screen and (min-width: $breakpointLarge) {
        column-count: 3;
      }

      > div {
        margin-bottom: .25rem;
      }

      label {
        cursor: pointer;
      }
    }

    p {
      font-size: .75rem;
    }
  }

  .jumpNav {
    max-width: 400px;
    margin: 0 auto 1.5rem;
  }
}

.armyList__timestamp {
  margin-top: -.125in;
  margin-bottom: .125in;
  text-align: center;
  display: none;

  @media print {
    display: block;
  }
}

.armyList__intro {
  @media screen {
    height: 0;
    overflow: hidden;
  }

  @media print {
    margin-bottom: .25in;
    display: block;
  }

  h1 {
    font-family: $headerFont;
    font-weight: normal;

    @media print {
      line-height: 1.2;
    }
  }
}

.armyList__referenceChart {
  table {
    tr {
      td,
      th {
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
}

.armyList__columns {
  break-after: page;
  overflow: hidden;

  &:not(.single) {
    @media print {
      column-count: 2;
      column-gap: .3125in;
      column-fill: auto;
    }
  }

  @media screen {
    .armyList__section:first-of-type {
      .editSectionLink:first-of-type {
        top: 0;
      }

      h2:first-of-type {
        margin-top: 0;
      }
    }
  }
}

.armyList__section {
  @media print {
    margin-bottom: .3125in;
    overflow: hidden;
  }

  .sectionBody {
    // margin-top: -1rem;

    @media print {
      margin-top: 0;
    }
  }
}

.armyList__rule {
  .ruleHeader & {
    @media print {
      margin-bottom: .3125in;
    }
  }

  &.hasUnits {
    .expandedList {
      @include expandedList;
    }
  }

  .richText {
    &.blockHeader {
      > p:first-of-type  {

        + ul,
        + table {
          margin-top: -.75em;
          @media print {
            margin-top: -.09375in;
          }
        }
      }
    }
  }
}

.armyList__section,
.armyList__unit,
.armyList__rule,
.referenceChart,
.additionalResource {
  > h2 {
    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.additionalResource {
  table {
    tr {
      td,
      th {
        &:first-child {
          font-weight: bold;
        }
        &:not(:first-child) {
          text-align: center;
        }
      }
    }
  }
}

.armyList__unit {
  &:nth-of-type(n+2) {
    @media screen {
      margin-top: 4rem;
    }

    @media print {
      margin-top: .25in;
    }
  }

  .unitOverview {
    break-inside: avoid;
  }

  .unitHeader {
    font-weight: bold;
    display: flex;
    justify-content: space-between;
    line-height: 0;
    align-items: flex-end;
    position: relative;
    overflow: hidden;

    @media print {
      margin-bottom: .0625in;
    }

    > * {
      @media print {
        margin-bottom: .0625in;
      }
    }

    .name,
    .cost {
      margin: 0;
      line-height: 1.2;
      position: relative;
      z-index: 1;

      @media print {
        line-height: 1;
      }
    }

    .name {
      @media print {
        margin-right: .25in;
        text-transform: uppercase;
      }

      > span {
        background: #fff;
        padding-right: 6px;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }

        @media screen {
          @media screen and (min-width: $breakpointSmall) {
            font-size: 1.25rem;
          }
        }

        @media print {
          line-height: 1;
        }

        em {
          text-transform: none;
          font-weight: normal;

          &:before {
            content: ' ';
            display: block;
          }
        }
      }
    }

    .cost {
      white-space: nowrap;
      background: #fff;
      padding-left: 6px;

      @media screen {
        @media screen and (min-width: $breakpointSmall) {
          font-size: 1.25rem;
        }
      }
    }

    .spacer {
      margin: 0 6px;
      min-width: 20px;
      flex: 1;
      overflow: hidden;
      height: 10px;
      white-space: nowrap;
      position: absolute;

      @media print {
        line-height: 1;
        height: auto;
        min-width: .25in;
      }
    }
  }

  .unitDetails {
    > *:not(:last-child),
    > .richText > p:last-of-type,
    > .richText > ul:last-of-type,
    > .richText > ol:last-of-type {
      @media print {
        margin-bottom: .0625in;
      }
    }

    .optionalRules {
      margin-bottom: 0;

      + div {
        > p:first-of-type {
          margin-top: 0;
        }
      }
    }
  }

  ul,
  ol {
    li {
      margin: 0;

      p {
        margin: 0;
      }

      ul {
        margin: 0;
        list-style-type: "\2013\00A0\00A0";
      }
    }    
  }

  .expandedList {
    @include expandedList;
  }

  .richText {
    &.blockHeader {
      > p:first-of-type  {

        + ul,
        + table {
          margin-top: -1em;
        }
      }
    }
  }
}

.tableWrapper {
  padding: 1px;
  overflow-x: auto;
  overflow-y: hidden;

  table {
    margin: 0;

    * {
      @media screen {
        font-size: .9rem;

        @media screen and (min-width: $breakpointSmall) {
          font-size: 1rem;
        }
      }
    }

    td, th {
      @media screen {
        font-size: .9rem;

        @media screen and (min-width: $breakpointSmall) {
          font-size: 1rem;
        }
      }
    }
  }

  @media print {
    padding: 0;
    overflow: auto;
  }
}

.genericTable {
  width: 100%;
  border-collapse: collapse;

  * {
    @media screen {
      font-size: .9rem;

      @media screen and (min-width: $breakpointSmall) {
        font-size: 1rem;
      }
    }
  }

  td, th {
    padding: 2px;
    text-align: center;
    line-height: 1.1;

    @media screen {
      font-size: .9rem;

      @media screen and (min-width: $breakpointSmall) {
        font-size: 1rem;
      }
    }
  }

  thead {
    tr {
      border-bottom: 1px solid #000;
    }
  }
}

.unitTable {
  break-inside: avoid;

  * {
    @media screen {
      font-size: .9rem;

      @media screen and (min-width: $breakpointSmall) {
        font-size: 1rem;
      }
    }
  }

  td, th {
    width: 7%;

    &:first-child {
      padding-left: 4px;
      width: 37%;
    }
  }

  tbody {
    tr {
      &:nth-child(even) {
        background: #ddd;

        td {
          position: relative;
          z-index: 1;
          overflow: hidden;

          &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-bottom: 100px solid #ddd;
            box-sizing: border-box;
          }
        }
      }
    }

    td {
      &:first-child {
        text-align: left;
      }
    }
  }
}

.profileTable {
  break-inside: avoid;

  td, th {
    width: 30%;
    vertical-align: top;

    &:last-of-type {
      width: 40%;
      white-space: nowrap;
    }
  }
}

.chartTable {
  border: 1px solid #000;

  td, th {
    padding: .25rem .5rem;
    border: 1px solid #000;
    text-align: left;
    vertical-align: top;

    @media print {
      break-inside: avoid;
      padding: 3px 5px;
    }

    &.nowrap {
      white-space: nowrap;
    }

    p {
      margin: 0 0 .5em;

      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }
}

.hide {
  display: none !important;
  visibility: hidden !important;
}

.show {
  display: block !important;
  visibility: visible !important;
}

.editEntryLink,
.editSectionLink {
  position: relative;
  top: .75rem;

  @media print {
    display: none;
  }
}

.editSectionLink {
  top: 3.5rem;
}

.backToTop {
  position: fixed;
  bottom: 50px;
  right: 40px;

  @media print {
    display: none !important;
  }
}

/**
 * Digital Rulebook
 */

.rulebook {
  @media print {
    font-family: $printFont;
    font-size: 9pt;
    line-height: 1.2;
    column-count: 2;
    column-gap: .3125in;
    column-fill: auto;
    overflow: hidden;
    text-align: justify;
  }

  &.singleColumn {
    @media print {
      column-count: auto;
      column-gap: normal;

      section {
        margin-bottom: 1.5rem;
        padding-bottom: 1.5rem;
      }
    }
  }

  .chapter {
    font-size: 2rem;

    @media print {
      > span:first-of-type {
        font-family: $headerFont;
        text-transform: uppercase;
        font-weight: normal;
        line-height: 1;
      }

      font-size: 16pt;
    }
  }

  .chapter,
  .rule {
    text-align: left;
  }

  b,
  strong {
    font-weight: bold;
  }

  ul,
  ol {
    @media print {
      padding-left: .1875in;
    }
  }

  section {
    margin-bottom: 3rem;
    padding-bottom: 3rem;
    border-bottom: 1px solid #ccc;

    @media screen and (min-width: $breakpointSmall) {
      margin-bottom: 4.5rem;
      padding-bottom: 4.5rem;
    }

    &:last-of-type {
      margin-bottom: 0;
      padding-bottom: 0;
      border-bottom: 0; 
    }

    @media print {
      margin-bottom: 0;
      padding-bottom: 0;
      break-before: column;
      border-bottom: 0;
      border-top: 4px solid #ccc;
    }

    .rule {
      margin-top: 3rem;
      font-size: 1.5rem;

      @media print {
        margin-top: 1rem;
        font-size: 13pt;

        > span:first-of-type {
          font-family: sans-serif;
        }
      }
    }

    article,
    .richText {
      h1, h2 {
        font-size: 1.25rem;

        @media print {
          font-size: 11pt;
          font-family: sans-serif;
        }
      }

      h3 {
        font-size: 1.125rem;

        @media print {
          font-size: 10pt;
          font-family: sans-serif;
        }
      }
    }

    blockquote {
      @media print {
        padding: 8px;
        font-size: 9pt;
        line-height: 1.2;
        border: 1px solid #ddd;
        background: #ddd;
      }
    }

    ol ol {
      list-style-type: lower-alpha;
    }

    .description {
      font-style: italic;
    }
  }

  .toc {
    ul {
      margin: 0;
      padding: 0;
      list-style: none;
      column-count: none;
      
      @media screen and (min-width: $breakpointSmall) {
        column-count: 2;
        column-gap: 1em;
      }

      @media screen and (min-width: $breakpointLarge) {
        column-count: 3;
      }

      @media print {
        list-style: none;
        column-count: none;
        font-family: sans-serif;
      }

      li {
        margin: 0;
        padding: 0;

        @media print {
          &:nth-child(n+2) {
            margin-top: 5px;
          }

          a {
            font-weight: bold;
          }
        }
      }
    }
  }

  .embeddedAsset {
    max-width: 600px;
    margin: 1rem auto;

    @media print {
      max-width: 4in;
    }

    .image {
      width: 100%;
      height: auto;
      display: block;
    }

    figcaption {
      margin-top: .5rem;
      font-style: italic;
      font-size: .9rem;
      line-height: 1.2;

      @media print {
        font-size: 8pt;
        text-align: left;
      }
    }
  }

  .page,
  .pageAdditional {
    font-weight: normal;
    font-size: .8rem;
    display: block;
    margin-top: .25rem;
    color: var(--gray-500);

    @media print {
      font-family: sans-serif;
      color: #999;
      font-size: 7pt;
      margin-top: .025in;
    }
  }

  .page {
    white-space: nowrap;
  }

  .pageAdditional {
    margin-left: 0;

    @media print {
      display: block;
    }
  }
}

.rulebookTitle {
  @media screen {
    height: 0;
    overflow: hidden;
  }

  @media print {
    margin-top: 0;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 24pt;
    line-height: 1.2;
  }

  span {
    font-family: $headerFont;
    text-transform: uppercase;
    font-weight: normal;
    line-height: 0;
  }
}

.rulebookAbout {
  margin-top: .5rem;
  text-align: center;
  display: none;

  @media print {
    font-size: 10pt;
    display: block;
  }
}

.columnOption {
  label {
    margin: 1rem auto 0;
  }
}

.noPrint {
  @media print {
    display: none;
  }
}